.signout_button button {
    width: 100%;
    height: 62px;
    border: none;
    color: #455560 !important;
    text-align: start;
    font-weight: 500;
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    background-color: #fff !important;
    position: absolute;
    bottom: 0;
    border-radius: 0;
    padding: 0 24px;
}
.table_deleted_bg{
    background-color:rgba(255, 251, 251, 0.308) !important;
    /* opacity: 0.6; */
    color: rgb(197, 197, 197);
}
